.container {
    overflow-x: hidden;
}
.mainContent {
    flex-grow: 1;
    /*padding: 16px;*/
    margin-right: 160px;
    transition: margin-right 0.3s;
    position: relative;
}

.sidebarOpen {
    margin-right: 50px;
}



@keyframes lightFocus {
    0% {
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.7) 70%);
    }
    25% {
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.6) 70%);
    }
    50% {
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(0, 0, 0, 0.5) 70%);
    }
    75% {
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.6) 70%);
    }
    100% {
        background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.7) 70%);
    }
}

@keyframes waterReflection {
    0% {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
        background-size: 200% 200%;
        transform: translate(0, 0);
    }
    25% {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 200% 200%;
        transform: translate(-5px, -5px);
    }
    50% {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 75%, transparent);
        background-size: 200% 200%;
        transform: translate(-10px, -10px);
    }
    75% {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 200% 200%;
        transform: translate(-5px, -5px);
    }
    100% {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
        background-size: 200% 200%;
        transform: translate(0, 0);
    }
}

@keyframes pulsate {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.8;
    }
}

.heroSection {
    height: 100vh;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;

    animation: lightFocus 8s infinite, waterReflection 5s infinite, pulsate 4s infinite;
}

.heroSection::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
    animation: rotate 10s linear infinite;
    pointer-events: none;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.threeContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
}

.heroContent {
    z-index: 2;
    padding: 0 16px;
    max-width: 50%;
}

.toolBar {
    background-color: rgb(36, 46, 56); /* Change the background color as needed */
    /*background-color: #d32f2f;*/
}

@media (max-width: 960px) {
    .heroContent {
        max-width: 70%;
    }
}

@media (max-width: 600px) {
    .heroContent {
        max-width: 100%;
        text-align: center;
    }
}

#menus {
    background-color: #e0f7fa;
}

#news {
    background-color: #fff3e0;
}

#tips {
    background-color: #f3e5f5;
}

#new-launches {
    background-color: #e8f5e9;
}

#resale {
    background-color: #f9fbe7;
}

#for-rent {
    background-color: #fce4ec;
}

.section {
    padding: 80px 0;
}


#properties {
    background-color: #f5f5f5;
}

#about {
    background-color: #fff;
}

#services {
    background-color: #f5f5f5;
}

#contact {
    background-color: #fff;
}