.disclaimerBox {
  background-color: #4b4b4b;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  user-select: none; /* 禁止文本选择 */
}

.disclaimerText {
  font-style: italic;
  font-size: 0.6rem;
  color: #bdbdbd;
  text-align: center;
  line-height: 1.5;
  user-select: none; /* 禁止文本选择 */
}
