.container {
    padding: 16px;
}

.contentContainer {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
}

/*.sideRecommend {*/
/*    word-break: break-word; !* Ensure long words break *!*/
/*    width: 25%;*/
/*    padding-right: 16px;*/
/*    margin-right: 16px; !* Add margin to maintain spacing *!*/
/*}*/

.mainContent {
    width:100%;
    /*width: calc(75% - 16px); !* Adjust width to account for the margin *!*/
    /*margin-left: 16px; !* Add margin to maintain spacing *!*/
}


.legend {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.legendItem {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 8px;
}

.legendCircle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.otherInfoContainer {
    display: flex;
    flex-direction: row;
}

.mapSection {
    flex: 1;
    height: 350px;
}

.sidebarSection {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.categoriesContainer {
    flex: 1;
    overflow-y: scroll;
    height: 350px;
    padding: 2px;
}

.detailsContainer {
    flex: 2;
    overflow-y: scroll;
    height: 350px;
    padding: 2px;
}

.categoryItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    background-color: #333333; /* 统一的深色背景 */
    margin-bottom: 8px;
}

.selectedCategory {
    box-shadow: 0 0 10px #d9d9d9; /* 统一的阴影高亮效果 */
}

.categorySquare {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 8px;
}

.tableContainer {
}

.busRouteBadge {
    display: inline-block;
    background-color: #3b3b3b;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 2px 8px;
    margin: 2px;
    font-size: 12px;
}

.busRoutesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

@media (max-width: 800px) {
    .sideRecommend {
        width: 25%;
        padding-right: 6px;
        margin-right: 6px; /* Add margin to maintain spacing */
    }

    .mainContent {
        /*width: calc(75% - 6px); !* Adjust width to account for the margin *!*/
        margin-left: 6px; /* Add margin to maintain spacing */
    }

    .otherInfoContainer {
        flex-direction: column;
    }

    .mapSection {
        order: 1;
        height: 200px;
    }

    .sidebarSection {
        order: 2;
        flex-direction: row;
        margin-top: 10px;
    }

    .categoriesContainer {
        flex: 1;
        overflow-y: scroll;
        width: 30%;
        height: 150px;
    }

    .detailsContainer {
        flex: 2;
        overflow-y: scroll;
        width: 70%;
        height: 150px;
    }
}

/* Custom Scrollbar Styles */
.categoriesContainer::-webkit-scrollbar,
.detailsContainer::-webkit-scrollbar {
    width: 8px;
}

.categoriesContainer::-webkit-scrollbar-thumb,
.detailsContainer::-webkit-scrollbar-thumb {
    background-color: #5e5e5e;
    border-radius: 10px;
}

.categoriesContainer::-webkit-scrollbar-thumb:hover,
.detailsContainer::-webkit-scrollbar-thumb:hover {
    background-color: #9f9f9f;
}

.categoriesContainer::-webkit-scrollbar-track,
.detailsContainer::-webkit-scrollbar-track {
    background-color: #232323;
    border-radius: 10px;
}
