.stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: stretch;
    gap: 20px;
}

.roundedBox {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 200px;
    text-align: center;
    margin: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.roundedBox:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.roundedBox .label {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 10px;
}

.roundedBox .number {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
}

.roundedBoxBlue {
    background-color: #3498db;
}

.roundedBoxGreen {
    background-color: #2ecc71;
}

.roundedBoxRed {
    background-color: #e74c3c;
}

.roundedBoxYellow {
    background-color: #f1c40f;
}

.iconBackground {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 3em;
    color: rgba(255, 255, 255, 0.2);
}

.notification {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 10px;
    min-width: auto;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    background-color: red;
    color: white;
    display: inline-block;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.subtitle {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
}
