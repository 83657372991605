.container {
  margin-top: 30px;
  margin-bottom: 20px;
}

.propertyDetailsHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  height: 35px;
  background-color: #252525;
  border-radius: 4px;
}

.sectionHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #252525;
  border-radius: 4px;
}

.sectionTitle {
  margin-left: 10px;
}

.tableWrapper {
  max-height: 300px;
  overflow-y: auto;
}

.tableWrapper::-webkit-scrollbar {
  width: 8px;
}

.tableWrapper::-webkit-scrollbar-track {
  background: #181818;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.tableWrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}