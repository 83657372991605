.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instructions {
    margin-bottom: 20px;
    text-align: center;
}

.scheduleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.headerRow {
    display: flex;
    width: 100%;
    justify-content: center;
}

.timePeriodColumn {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    align-items: center;
}

.timePeriod {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Adjust based on your preference */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    margin-bottom: 5px;
    flex-grow: 1;
}

.emptyHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px; /* Match the height of timePeriod */
}

.timeColumn {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.timeLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px; /* Match the height of timeSlot */
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 5px;
}

.dateRow {
    display: flex;
    gap: 5px;
    margin-left: 5px;
}

.dateCell {
    text-align: center;
    border-radius: 4px;
    width: 60px; /* Adjusted width for mobile */
    display: flex;
    flex-direction: column;
}

.timeSlotsColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.timeSlot {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 20px; /* Adjust height as requested */
}

.selected {
    background-color: #1976d2;
    color: white;
}

.morning {
    border-color: #FFD700; /* 金色 */
}

.noon {
    border-color: #FF8C00; /* 深橙色 */
}

.afternoon {
    border-color: #ADFF2F; /* 绿黄色 */
}

.evening {
    border-color: #1E90FF; /* 道奇蓝 */
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.navButton {
    margin-top: 10px;
}

.resetButton {
    margin-top: 10px;
}

.selectedTime {
    max-width: 300px;
    /*display: block;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
}

.submitButton {
    margin-top: 20px;
}
