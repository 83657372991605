/****** 容器布局 ******/
.storyContainer {
  max-width: 960px;
  width: 100%;
  height: 500px;
  margin: 0 auto 60px;
  position: relative;
  overflow: hidden;
}

/* 顶部操作栏 */
.controlBar {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.playButton {
  background-color: #506f90;
  color: #fff;
  border: none;
  padding: 8px 24px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.playButton:hover {
  background-color: #6b8aa9;
}

/****** 滚动区 ******/
.scrollableArea {
  position: relative;
  overflow-y: auto;
  height: calc(100% - 57px);
  scrollbar-width: none; /* Firefox */
}

.scrollableArea::-webkit-scrollbar {
  display: none;
}

/****** story项 ******/
.storyItem {
  position: relative;
  padding: 24px 32px 24px 72px;
  box-sizing: border-box;
  color: #e0e0e0;
}

/* 左侧竖线 */
.verticalLine {
  position: absolute;
  left: 36px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1;
}

/* 圆点 */
.storyDot {
  position: absolute;
  left: 27px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #6b8aa9;
  top: 30px;
  z-index: 2;
}

/* 主体内容 */
.itemMain {
  position: relative;
  z-index: 3;
}

.itemYear {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #fff;
}

.itemCard {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 10px;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.cardImage {
  width: 120px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid rgba(255,255,255,0.1);
  cursor: pointer;
}

.itemInfo {
  display: flex;
  flex-direction: column;
}

.itemTitle {
  margin: 0 0 4px 0;
  font-size: 16px;
  color: #fff;
}

.itemDescription {
  margin: 0;
  font-size: 14px;
  color: #d0d0d0;
  line-height: 1.4;
}

.bottomSpacer {
  height: 200px;
}

/****** 右侧小圆点 + 上下按钮 ******/
.dotWrapper {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.arrowBtn {
  background-color: transparent !important;
  color: #fff;
  font-size: 32px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.3s;
  border: none;
  width: auto;
  height: auto;
  padding: 0;
}

.arrowBtn:hover {
  opacity: 0.7;
}

.dotList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.3);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.dot:hover {
  background-color: rgba(255,255,255,0.6);
  transform: scale(1.1);
}

.dot.active {
  background-color: #6b8aa9;
  transform: scale(1.3);
}

/****** 大图模式 ******/
.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreenPhotoContainer {
  position: relative;
}

.fullscreenPhoto {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.fullscreenArrowButton {
  background-color: rgba(124,124,124,0.5);
  color: white;
  border: none;
  padding: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
  font-size: 32px;
}

.fullscreenArrowButton:nth-of-type(1) {
  left: 20px;
}

.closeButton {
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 100px));
  right: 200px;
  background-color: rgba(124,124,124,0.5);
  padding: 24px;
  border: none;
  color: white;
  font-size: 32px;
  cursor: pointer;
  z-index: 1001;
}

/****** 响应式 ******/
@media (max-width: 600px) {
  .storyContainer {
    height: 450px;
    margin-bottom: 40px;
  }

  .storyItem {
    padding: 16px 24px 16px 60px;
  }

  .verticalLine {
    left: 28px;
    width: 1.5px;
  }

  .storyDot {
    left: 20px;
    width: 16px;
    height: 16px;
  }

  .itemYear {
    font-size: 16px;
  }

  /* 让 .cardImage 最大3个一排 */
  .cardImage {
    width: calc((100% / 3) - 16px); /* 3张一排 + 考虑左右gap共16px */
    height: auto;
  }

  .arrowBtn {
    font-size: 28px;
    line-height: 28px;
  }

  .dot {
    width: 14px;
    height: 14px;
  }

  .dotList {
    gap: 10px;
  }

  .bottomSpacer {
    height: 150px;
  }

  .fullscreenArrowButton {
    padding: 16px;
    font-size: 24px;
  }

  .closeButton {
    padding: 16px;
    font-size: 24px;
  }
}
