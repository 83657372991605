.rightSideDashboard {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: #242E38;
    color: white;
    overflow-y: auto;
    transition: width 0.3s;
    z-index: 1000;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: -3px 0 8px rgba(0, 0, 0, 0.3);
}

.open {
    width: 160px;
}

.closed {
    width: 50px;
}

.menu {
    margin-bottom: 70px;
}

.menuItem {
    padding: 0px;
}

.menuIcon {
    width: 15px;
    margin-left: -5px;
}

.menuText {
    margin-left: -12px;
}

.backButtonContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

.backIcon {
    color: #fff;
    margin-right: 8px;
}

.backText {
    color: #fff;
    font-size: 16px;
}


.languageToggle {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
}

.toggleButton {
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    transition: all 0.3s ease;
}

.toggleButton.Mui-selected {
    background-color: #1976d2;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
}

.toggleButton.Mui-selected:hover {
    background-color: #1565c0;
}

.verticalLanguageToggle {
    flex-direction: column;
}

.bookmarkCount {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
}

.propertyBookmarkIconAnimation {
    animation: pulsePropertyBookmarkIcon 1s ease-in-out;
}

@media (max-width: 600px) {
    .rightSideDashboard {
        width: 50px;
    }

    .open {
        width: 160px;
    }
}




@keyframes pulsePropertyBookmarkIcon {
    0%, 100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 225, 255, 0.5); /* Blue glow */
    }
    50% {
        transform: scale(1.2);
        box-shadow: 0 0 15px 10px rgba(0, 217, 255, 0.3); /* Blue glow */
    }
}