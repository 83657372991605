.mapContainer {
  /*height: 350px;*/
  height: 100%;
  width: 100%;
}

@media (max-width: 800px) {
  .mapContainer {
    height: 200px; /* 确保在小屏幕上有足够高度 */

  }
}

@media (max-width: 600px) {
  .mapContainer {
    height: 200px; /* 确保在小屏幕上有足够高度 */

  }
}

.categoryIcon {
  border-radius: 50%;
}

.highlighted {
  box-shadow: 0 0 15px 5px rgba(0,0,0,0.3);
}

