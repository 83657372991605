.photoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.selectedPhotoContainer {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

.selectedPhoto {
    width: 600px;
    height: 400px;
    object-fit: cover;
    cursor: pointer;
}


.arrowButton {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 24px; /* Increased padding for larger buttons */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 32px; /* Increased font size for larger buttons */
}

.arrowButton:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.arrowButton:nth-of-type(1) {
    left: 0;
}

.arrowButton:nth-of-type(2) {
    right: 0;
}

.thumbnailsContainer {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 4px 0;
}

.thumbnailsWrapper {
    display: flex;
    overflow-x: auto;
    flex-grow: 1;
}

.thumbnail {
    width: 100px;
    height: 80px;
    object-fit: cover;
    margin: 0 4px;
    cursor: pointer;
    border: 5px solid transparent;
    transition: border 0.3s ease;
}

.selectedThumbnail {
    /*border-color: #40a82e;*/
    border: 5px solid #058eef;
}

.fullscreenOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreenPhotoContainer {
    position: relative;
}

.fullscreenPhoto {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.fullscreenArrowButton {
    background-color: rgba(124, 124, 124, 0.5);
    color: white;
    border: none;
    padding: 24px; /* Increased padding for larger buttons */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    font-size: 32px; /* Increased font size for larger buttons */
}

.fullscreenArrowButton:nth-of-type(1) {
    left: 20px; /* Fixed position on the left side */
}

.fullscreenArrowButton:nth-of-type(2) {
    right: 200px; /* Fixed position on the right side */
}

.closeButton {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 100px)); /* Move up by 100px */
    right: 200px; /* Fixed position on the right side */
    background-color: rgba(124, 124, 124, 0.5);
    padding: 24px; /* Increased padding for larger buttons */
    border: none;
    color: white;
    font-size: 32px; /* Increased font size for larger buttons */
    cursor: pointer;
    z-index: 1001;
}

.thumbnailArrowButton {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 16px; /* Increased padding for larger buttons */
    cursor: pointer;
    font-size: 24px; /* Increased font size for larger buttons */
}

/* Custom Scrollbar Styles */
.thumbnailsWrapper::-webkit-scrollbar {
    height: 8px;
}

.thumbnailsWrapper::-webkit-scrollbar-thumb {
    background-color: #5e5e5e;
    border-radius: 10px;
}

.thumbnailsWrapper::-webkit-scrollbar-thumb:hover {
    background-color: #9f9f9f;
}

.thumbnailsWrapper::-webkit-scrollbar-track {
    background-color: #232323;
    border-radius: 10px;
}


.selectedPhoto {
    width: 600px;
    height: 400px;
    object-fit: cover;
    cursor: pointer;
}

@media (max-width: 768px) {
    .selectedPhoto {
        width: 100%;
        height: auto;
    }

    .thumbnail {
        width: 80px;
        height: 60px;
    }

    .fullscreenPhoto {
        width: 100%;
        height: auto;
    }

    .fullscreenArrowButton {
        padding: 16px; /* Reduce padding for smaller screens */
        font-size: 24px; /* Reduce font size for smaller screens */
    }

    .closeButton {
        padding: 16px; /* Reduce padding for smaller screens */
        font-size: 24px; /* Reduce font size for smaller screens */
    }
}
