.container {
  padding: 20px;
}

.filterContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter {
  background-color: #333333;
  color: #ffffff;
}

.contentContainer {
  display: flex;
  flex-direction: row;
}

.mapSection {
  flex: 1;
  margin-right: 20px;
  height: 450px;
  /*min-height: 300px; !* 添加最小高度 *!*/
  /*display: flex;*/
  /*flex-direction: column;*/
}

.propertyList {
  flex: 1;
  /*height: calc(450px - 20px); !* 450px - (10px top padding + 10px bottom padding) *!*/
  /*background-color: #1e1e1e;*/
  padding: 10px;

  /*overflow-y: auto;*/
}

/*.propertyList::-webkit-scrollbar {*/
/*  width: 8px;*/
/*}*/

/*.propertyList::-webkit-scrollbar-track {*/
/*  background: #181818;*/
/*}*/

/*.propertyList::-webkit-scrollbar-thumb {*/
/*  background-color: #888;*/
/*  border-radius: 4px;*/
/*}*/

/*.propertyList::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #555;*/
/*}*/


.propertyItem {
  display: flex;
  flex-direction: row;
  background-color: #333333;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.propertyImage {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
  cursor: pointer;
}

.propertyDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.propertyNameLink, .propertyImageLink {
  text-decoration: none;
  color: inherit;
}

.propertyName {
  font-weight: bold;
  color: #90caf9;
}

.propertyDescription {
  margin: 5px 0;
  color: #ffffff;
}

.propertyPrice {
  margin: 5px 0;
  color: #ffd700;
}

.propertyLocation {
  margin: 5px 0;
  color: #ffffff;
}

.bookmarkButton {
  align-self: flex-end;
  color: #f48fb1;
}

/* Responsive design */
@media (max-width: 800px) {
  .filterContainer {
    flex-direction: column;
  }

  .contentContainer {
    flex-direction: column;
  }

  .mapSection, .propertyList {
    margin-right: 0;
  }

  .mapSection {
    height: 200px;
  }

  .propertyList {
    margin-top: 20px;
    overflow-y: auto; /* 确保在移动设备上启用垂直滚动条 */
    /*height: 250px; !* Adjusted height for mobile view *!*/
    /*height: calc(250px - 20px); !* 250 - (10px top padding + 10px bottom padding) *!*/
    /*min-height: 250px; !* 好像有bug，如果不设置min-height 和max-height ，滚动条无法显示 *!*/
    /*max-height: 250px;*/
  }
}

.propertyNameLink, .propertyImageLink {
  text-decoration: none;
  color: inherit;
}
