/* Login.module.css */
body, html {
    height: 100%;
    margin: 0;
}

.wrapper {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    height: 100vh; /* 视口高度 */
    background-color: #2C3A47;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #34495E;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    width: 300px; /* 或其他所需的宽度 */
}

.loginContainer h1 {
    color: #AAB9CF;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginInput {
    width: calc(100% - 30px); /* 减少输入框的宽度 */
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 2px solid #AAB9CF;
    border-radius: 10px;
    background-color: transparent;
    color: #AAB9CF;
    font-size: 18px;
    transition: border-color 0.3s;
    display: block; /* 确保输入框是块级元素 */
}

.loginInput:focus {
    outline: none;
    border-color: #74b9ff;
}

.loginButton {
    width: 90px;
    padding: 10px 0;
    border: none;
    border-radius: 10px;
    background-color: #74b9ff;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
    display: inline-block; /* 使按钮成为内联块级元素 */
}

.loginButton:hover {
    background-color: #81ecec;
}
