/* DashboardLeftSideBar.module.css */
.sideBar {
  height: calc(100vh);
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  /*overflow-y: auto;*/
  overflow-x: visible; /* 确保超出的部分可见 */
  /*overflow: visible; !* 确保侧边栏允许内容溢出 *!*/
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
  /*border-right: 3px solid rgba(0, 0, 0, 0.05);*/
  transition: width 0.3s ease;
  /*background-color: #242E38;*/
  background-color: #2C3A47;
}

.sideBarTabs {
  overflow-y: scroll; /* 始终显示滚动条 */
  overflow-x: visible; /* 确保超出的部分可见 */
  height: calc(100vh - 160px); /* 确保滚动区域的高度 */
  position: relative;
  /*width: 240px;*/
  border-radius: 30px;
  width: 100%;
  border-right: 3px solid rgba(0, 0, 0, 0.05);
}

/* 针对 Webkit 浏览器，如 Safari 和 Chrome */
.sideBarTabs::-webkit-scrollbar {
  width: 2px; /* 滚动条宽度 */
}

.sideBarTabs::-webkit-scrollbar-track {
  background: rgba(54, 54, 54, 0.5); /* 滚动条轨道颜色 */
  border-radius: 10px; /* 滚动条轨道圆角 */
}

.sideBarTabs::-webkit-scrollbar-thumb {
  /*background-color: #c0c0d0; !* 滚动条颜色 *!*/
  border-radius: 10px; /* 滚动条圆角 */
  border: #2aabd2 1px dashed;
}

/* 让滚动条在左侧显示 */
.sideBarTabs {
  direction: rtl; /* 改变内容的方向为从右到左 */
}

.sideBarTabs > * {
  direction: ltr; /* 让子元素的方向恢复为从左到右 */
}

.sideBar.open {
  width: 240px;
}

.sideBar.closed {
  width: 50px;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
}

.logo img {
  width: 100px;
  border: 1px solid #ccc;
}

.logoText {
  margin-top: 10px;
  font-weight: bold;
}

.toggleButton {
  position: absolute;
  /*position: fixed;*/
  top: 50%;
  right: -30px;
  /*margin-left: 600px;*/
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: right 0.3s ease;
  z-index: 1000;
}

.iconBackgroundCircle {
    background-color: #575757; /* 圆圈的背景颜色 */
    border-radius: 50%; /* 使背景成为圆形 */
    padding: 5px; /* 内边距使图标和圆圈之间有一些间距 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.sideBarA {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  transition: all 0.3s ease;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sideBarA:hover,
.active {
  background-color: #81ecec;
  color: white;
  border-radius: 20px;
  /*border: 1px solid #74b9ff;*/
  /*box-shadow: 0 0 10px rgba(80, 168, 217, 1), 0 0 20px 5px rgba(80, 168, 217, 0.8), 0 0 30px 15px rgba(80, 168, 217, 0.5);*/
}

.sideBarA i {
  margin-right: 10px;
  font-size: 20px;
}

.sideBarA:hover i,
.sideBarA.active i {
  color: white;
}

.sideBarTabs .sideBarA:first-child {
  margin-top: 20px; /* 第一个元素距离顶部20px */
}

.sideBarTabs .sideBarA:last-child {
  margin-bottom: 20px; /* 最后一个元素距离底部20px */
}

.divider {
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
