.twoColumnLayout {
    display: flex;
    justify-content: space-between; /* 两列内容分开 */
    align-items: flex-start; /* 如果需要垂直对齐左侧和右侧内容 */
}

.sideRecommend {
    flex-basis: 25%; /* 左侧宽度占25% */
    margin-right: 20px; /* 右侧间距 */
}

.mainContent {
    flex-basis: 70%; /* 右侧宽度占70% */

    line-height: 1.8;
    border-radius: 18px;
    max-width: 800px;
    margin: 0 auto;
    word-wrap: break-word;
    background-color: #202833;
    width: 100%; /* 确保宽度保持一致 */
    box-sizing: border-box; /* 包含内边距和边框在宽度内 */
}

.small {
    font-size: 16px;
}

.medium {
    font-size: 20px;
}

.large {
    font-size: 28px;
}

.watermarkOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* 确保遮罩不可点击 */
    background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.03) 0,
            rgba(255, 255, 255, 0.03) 1px,
            transparent 1px,
            transparent 20px
    ),
    repeating-linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.03) 0,
            rgba(255, 255, 255, 0.03) 1px,
            transparent 1px,
            transparent 20px
    );
    z-index: 1;
}

.watermarkText {
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 1); /* 设置水印颜色和透明度 */
    font-size: 30px; /* 可以调整字体大小 */
    white-space: nowrap;
    transform: rotate(-30deg);
    margin: 20px; /* 调整水印之间的间距 */
    pointer-events: none;
    opacity: 0.03; /* 透明度 */
    user-select: none;
}

.paragraphWrapper {
    position: relative;
    border-radius: 18px;
    padding: 16px;
    margin-bottom: 24px;
    overflow: hidden;
    user-select: none;
}

.paragraph {
    margin-bottom: 24px;
    text-indent: 2em;
    color: #c9c9c9;
    width: 100%; /* 确保段落宽度始终为 100% */
    box-sizing: border-box; /* 包括内边距和边框在宽度内 */
    overflow-wrap: break-word; /* 改为 overflow-wrap 防止长单词溢出 */
    word-break: break-word; /* 处理长单词，确保它们会在行末强制换行 */
    white-space: normal; /* 确保换行 */
}

.icon {
    height: 1em;
    vertical-align: middle;
}

.bookmarkMessageWrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    /*width: 100%; !* 确保宽度为100% *!*/
}

.bookmarkContainer {
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    background-color: #45b647;
    border-radius: 18px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    width: auto; /* 确保宽度根据内容自动调整 */
    /*max-width: 100%; !* 控制最大宽度 *!*/
}

.bookmarkContainer:hover {
    background-color: #4ecc51;
}

.addedBookmark {
    background-color: #2f2f2f; /* Example color for added bookmark */
}

.addedBookmark:hover {
    background-color: #2f2f2f;
}

.smallText {
    font-size: 12px;
    color: #aaa;
}

.bookmarkContainer svg {
    vertical-align: middle;
    margin-left: 8px;
}

.fontSizeContainer {
    border-radius: 18px;
    background-color: #202833;
}

.fontSizeSelector {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.fontSizeOption {
    margin: 0 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.fontSizeOption:hover {
    background-color: #555;
}

.selected {
    background-color: #555;
}

.expandButtonWrapper {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 56px;
}

.expandButton {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

.expandButton:hover {
    background-color: #555;
}

.expandButton::before,
.expandButton::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 20px;
    height: 2px;
    background-color: #595959;
}

.expandButton::before {
    left: -30px;
}

.expandButton::after {
    right: -30px;
}

.collapsed {
    overflow: hidden;
    position: relative;
    max-height: calc(var(--max-lines) * 1.2em); /* 动态高度计算 */
}

.collapsed::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6em; /* Increased height for better visibility */
    background: linear-gradient(transparent, #202833);
}

.expanded {
    max-height: none;
}

.preWrap {
    white-space: pre-wrap;
}

.fieldName {
    font-weight: bold;
    margin-bottom: 58px;
    text-align: center;
    font-size: 1.5em;
}

.ratingSection {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*max-width: 100%; !* 控制打分系统宽度 *!*/
    /*width: 100%;*/
    width: 200px;
}

.ratingRow {
    display: flex;
    align-items: center;
    /*width: 100%;*/
    /*max-width: 100%; !* 控制打分系统宽度 *!*/

}

.dimensionLabel {
    min-width: 80px;
    text-align: right;
    margin-right: 10px;
}

.ratingButtonContainer {
    flex: 1;
    text-align: center;
}

.ratingButton {
    /*width: 100%;*/
    width: 5px;
}

@media (max-width: 768px) {
    .mainContent {
        padding: 15px;
        font-size: 16px;
        line-height: 1.6;
    }

    .fontSizeOption {
        font-size: 10px;
        min-width: 20px;
        text-align: center;
        margin: 0 3px;
        padding: 6px;
        cursor: pointer;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }

    .selected {
        background-color: #555;
    }

    .small {
        font-size: 14px;
    }

    .medium {
        font-size: 18px;
    }

    .large {
        font-size: 24px;
    }

    .expandButton {
        padding: 4px 8px;
    }

    .expandButton::before,
    .expandButton::after {
        width: 15px;
    }

    .expandButton::before {
        left: -20px;
    }

    .expandButton::after {
        right: -20px;
    }

    .ratingSection {
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        /*max-width: 100%; !* 控制打分系统宽度 *!*/
        width: 100%;
        /*width: 200px;*/
    }

    .ratingButton {
        /*min-width: 30px;*/
        min-width: 5px;
        padding: 2px;
        font-size: 10px;
    }

    .dimensionLabel {
        min-width: 60px;
        text-align: right;
        margin-right: 5px;
    }

    .ratingButtonContainer {
        flex: 1;
        text-align: center;
    }
}
