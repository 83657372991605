/* Styling for the article page container */
.container {
    /*background-color: #111;*/
    color: #ddd;
    padding: 20px;
    border-radius: 8px;
    max-width: 1000px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

/* Styling for the title */
.articleTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 16px;
    text-align: center;
    font-weight: bold;
}

/* Styling for the images */

/* Outer container for centering the cover image */
.coverImageSectionContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0; /* Add some margin around the cover image */
}

/* Inner container wrapping the image */
.coverImageContainer {
    position: relative;
    display: inline-block; /* Make container size match the image */
    text-align: center;
}

/* Styling for the cover image */
.coverImage {
    display: block;
    max-width: 100%; /* Ensure the image scales properly */
    height: auto; /* Maintain the image's aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

/* Optional image overlay for cover image */
.imageOverlay {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-style: italic;
}


/* The outer container that spans 100% of the section */
.imageSectionContainer {
    width: 100%;
    display: flex; /* Flexbox for centering the inner container */
    justify-content: center; /* Center the image container */
    margin: 20px 0; /* Add some margin to separate from other content */
}

/* The inner container that wraps around the image and overlay */
.imageContainer {
    position: relative; /* For positioning the overlay */
    display: inline-block; /* The size of this container will adjust to the image size */
    text-align: center;
}

/* Styling for the image */
.sectionImage {
    display: block;
    max-width: 100%; /* Image takes full width of its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
}

/* The overlay positioned over the image */
.imageOverlay {
    position: absolute;
    bottom: 10px; /* 10px from the bottom of the image */
    right: 10px; /* 10px from the right edge of the image */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    color: #fff;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-style: italic;
}




/* Styling for the section text */
.sectionContent {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #e0e0e0;
    font-family: 'Open Sans', sans-serif;
    padding: 20px;
    background-color: #222;
    border-radius: 25px;
    user-select: none;
    pointer-events: none;  /* Make the entire text non-clickable except the help icon */
}

.sectionContent span {
    /*font-family: Arvo*/
}

/* Styling for the help icon container */
.helpIconContainer {
    pointer-events: auto; /* Ensure the icon is clickable */
    margin-left: 8px; /* Add some spacing from the text */
    vertical-align: middle; /* Align with the text center */
    cursor: pointer;
    color: #ccc; /* Default icon color */
    transition: color 0.3s;
    display: inline-flex; /* Use inline-flex for better alignment */
    align-items: center; /* Vertically center the icon */
}

.helpIconContainer:hover {
    color: #fff; /* Change color on hover */
}

/* Styling for the bookmark buttons */
/*.bookmarkButton {*/
/*    background-color: #1a73e8;*/
/*    color: white;*/
/*    margin: 5px;*/
/*    transition: background-color 0.3s;*/
/*}*/

/*.bookmarkedButton {*/
/*    background-color: #e84545;*/
/*    color: white;*/
/*    margin: 5px;*/
/*    transition: background-color 0.3s;*/
/*}*/

/*.bookmarkButton:hover, .bookmarkedButton:hover {*/
/*    background-color: #3c4043;*/
/*}*/

.bookmarkContainer {
    display: inline-flex; /* Use inline-flex for better alignment */
    align-items: center; /* Vertically center the icon with the text */
    text-align: center; /* Center the text and icon together */
    padding: 10px 20px;
    background-color: #45b647;
    border-radius: 18px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    width: auto; /* 确保宽度根据内容自动调整 */
    /*max-width: 100%; !* 控制最大宽度 *!*/
}

.bookmarkContainer:hover {
    background-color: #4ecc51;
}

.addedBookmark {
    background-color: #2f2f2f; /* Example color for added bookmark */
}

.addedBookmark:hover {
    background-color: #2f2f2f;
}

.smallText {
    font-size: 12px;
    color: #aaa;
}

/* Styling for the back buttons */
.backButton, .bottomBackButton {
    background-color: #555;
    color: white;
    padding: 6px 16px;
    margin: 5px;
    transition: background-color 0.3s;
}

.backButton:hover, .bottomBackButton:hover {
    background-color: #777;
}

/* Styling for the watermark container to cover the entire content */
/* Updated styling for the watermark container */
.watermarkContainer {
    position: absolute;
    top: 0;
    left: 30px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-auto-rows: 200px;
    justify-content: start; /* Aligns grid items from the start without extra spacing */
    align-content: start;
    gap: 200px 150px; /* Ensures the spacing between items is consistently 200px vertically and 300px horizontally */
    clip-path: inset(0 0 0 0); /* Ensure watermark content doesn't go beyond the container */
    overflow: hidden;
}

/* Updated styling for individual watermark items */
.watermark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px; /* Ensure the width matches the grid cell */
    height: 200px; /* Ensure the height matches the grid cell */


    font-weight: bold;
    /*transform: rotate(-30deg);*/
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden; /* Prevents any text from overflowing outside */
}


.watermark span {
    font-size: 2.3rem;
    font-family: "Arial Black";
    color: rgba(192, 192, 192, 0.05);
    display: block;
    text-align: center;
    padding: 2px;
}


/* Styling for dividers between sections */
.sectionDivider {
    margin: 20px 0;
    background-color: #444;
}


/* Mobile styling */
@media (max-width: 800px) {
    .watermarkContainer {
        left: 10px;
    }

    .watermark {
        width: 450px; /* Ensure the width matches the grid cell */
        height: 200px; /* Ensure the height matches the grid cell */
    }

    .watermark span {
        font-size: 2rem; /* Adjust font size for mobile */
        color: rgba(192, 192, 192, 0.08);
    }
}