body {
    background-color: #2C3A47;
}

.wrapper {
    display: flex;
    height: 100vh;
    padding-top: 70px;
}

.sideBarContainer {
    z-index: 100;
    overflow-x: visible;
}

.mainContent {
    flex-grow: 1;
    transition: margin-left 0.3s ease, width 0.3s ease;
}

.wrapper .sideBarContainer {
    width: 240px;
}

.wrapper .mainContent {
    margin-left: 240px;
    width: calc(100% - 240px);
}

.wrapper .sideBarContainer.closed {
    width: 60px;
}

.wrapper .mainContent.closed {
    margin-left: 60px;
    width: calc(100% - 60px);
}

img {
    display: block;
}

.grooveEffectHorizontal {
    background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
    height: 1px;
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 0;
    z-index: 1;
}

/*.topBar {*/
/*    background-color: #2C3A47;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    padding: 10px 0;*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    z-index: 5;*/
/*    height: 90px;*/
/*    box-sizing: border-box;*/
/*    border-bottom: 2px solid rgba(0, 0, 0, 0.05);*/
/*}*/

.profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #ccc;
}

/*.sideBar {*/
/*    height: 100vh;*/
/*    width: 240px;*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    overflow-y: auto;*/
/*    z-index: 3;*/
/*    padding-left: 10px;*/
/*    padding-right: 10px;*/
/*    border-right: 2px solid rgba(0, 0, 0, 0.05);*/
/*}*/

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;
}

.logo img {
    width: 100px;
    border: 1px solid #ccc;
}

.logoText {
    margin-top: 10px;
    font-weight: bold;
}

.sideBarA {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    transition: all 0.3s ease;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sideBarA:hover, .active {
    background-color: #81ecec;
    color: white;
    border-radius: 20px;
    border: 1px solid #74b9ff;
    /*box-shadow: 0 0 10px rgba(80, 168, 217,1),*/
    /*        0 0 20px 5px rgba(80, 168, 217,0.8),*/
    /*        0 0 30px 15px rgba(80, 168, 217,0.5);*/
}

.sideBarA i {
    margin-right: 10px;
    font-size: 20px;
}

.sideBarA:hover i, .sideBarA.active i {
    color: white;
}

.mainContent {
    width: calc(100% - 300px - 4rem);
    margin-left: 300px;
    margin-right: 4rem;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    z-index: 1;
}

.stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: stretch;
    gap: 20px;
}

.roundedBox {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 200px;
    text-align: center;
    margin: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.roundedBox:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.roundedBox .label {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 10px;
}

.roundedBox .number {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
}

.roundedBoxBlue {
    background-color: #3498db;
}

.roundedBoxGreen {
    background-color: #2ecc71;
}

.roundedBoxRed {
    background-color: #e74c3c;
}

.roundedBoxYellow {
    background-color: #f1c40f;
}

.iconBackground {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 3em;
    color: rgba(255, 255, 255, 0.2);
}

.notification {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 10px;
    min-width: auto;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    background-color: red;
    color: white;
    display: inline-block;
    z-index: 1;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.subtitle {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    margin-bottom: 20px;
    color: #fff;
    text-align: left;
}
