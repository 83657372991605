.sideContent {
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #1f1f1f;
    border-radius: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    color: #c5c5c5;
}

.title {
    font-size: 12px;
    margin-bottom: 16px;
    color: #c5c5c5;
    border-bottom: 2px solid #1e88e5;
    padding-bottom: 8px;
}

.recommendationsList {
    list-style: none;
    padding-left: 4px;
    margin: 0;
}

.recommendation {
    margin-bottom: 12px;
    border-bottom: 1px solid #444;
    padding-bottom: 12px;
}

.recommendation:last-child {
    border-bottom: none; /* Remove border from the last item */
}

.recommendation a {
    text-decoration: none;
    color: #b6b6b6;
    font-weight: 500;
    display: block;
    padding: 8px 0;
    border-radius: 4px;
    transition: background-color 0.3s;
    word-break: break-word; /* Ensure long words break */
}

.recommendation a:hover {
    color: #f0f0f0;
    background-color: #5488af;
    text-decoration: none;
}

.bookmarkButton {
    font-size: 12px;
    background-color: #45b647;
    border-radius: 10px;
    color: #fff;
    padding: 3px 5px 3px 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: 4px;
}

.bookmarkButton:hover {
    background-color: #4ecc51;
}

.addedBookmark {
    background-color: #aaa; /* Gray background for bookmarked */
}

.addedBookmark:hover {
    background-color: #aaa; /* Slightly lighter gray on hover */
}

.bookmarkButton svg {
    font-size: 14px;
    margin-left: 4px;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .title {
        padding-top: 12px;
        font-size: 8px;
        padding-left: 8px;
    }
    .sideContent {
        padding: 4px;
        font-size: 8px;
        width: 100%;
        max-width: none;
        margin-bottom: 16px;
    }
    .bookmarkButton {
        font-size: 8px;
    }
}
