.footerGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footerSection {
    min-width: 200px; /* Ensures a minimum width for mobile view */
    margin: 8px;
}
