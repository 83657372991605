.container {
    padding: 20px;
}

.filterContainer {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.filter {
    background-color: #333333;
    color: #ffffff;
}

.contentContainer {
    /*display: flex;*/
    /*flex-direction: row;*/


    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between; !* 确保两侧均分 *!*/
    /*gap: 20px; !* 两个列表间的间距 *!*/

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between; /* 确保左右分布 */
}

.featuredFrequentUseArticleContainer {
    font-size: 15px;
    color: #fff;
    background-color: #3eaf4e; /* Flat red color */
    padding: 10px 20px;
    border-radius: 0 25px 25px 0; /* Left side straight, right side rounded */
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); !* Adding box shadow *!*/
    display: inline-block; /* So the div fits the content */
    margin-bottom: 30px;
}

.allArticleContainer {
    font-size: 15px;
    color: #fff;
    background-color: #4da6ff; /* Flat blue color */
    padding: 10px 20px;
    border-radius: 0 25px 25px 0; /* Left side straight, right side rounded */
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); !* Adding box shadow *!*/
    display: inline-block; /* So the div fits the content */
    margin-bottom: 30px;
}


.mapSection {
    flex: 1;
    margin-right: 20px;
    height: 450px;
    /*min-height: 300px; !* 添加最小高度 *!*/
    /*display: flex;*/
    /*flex-direction: column;*/
}

.articleList {
    /*!*flex: 1;*!*/

    /*flex: 1 1 50%; !* 确保每个 articleList 占据 50% *!*/
    /*max-width: 50%; !* 确保每列最多占50%宽度 *!*/
    /*box-sizing: border-box; !* 确保 padding 和边框不会影响宽度 *!*/


    /*!*height: calc(450px - 20px); !* 450px - (10px top padding + 10px bottom padding) *!*!*/
    /*min-height: 400px;*/
    /*height: auto;*/
    /*!*background-color: #1e1e1e;*!*/
    /*padding: 10px;*/

    flex: 0 1 calc(50% - 10px); /* 确保占据父容器50%宽度减去gap一半 */
    max-width: calc(50% - 10px); /* 避免溢出 */
    box-sizing: border-box;


}

/*.articleList::-webkit-scrollbar {*/
/*  width: 8px;*/
/*}*/

/*.articleList::-webkit-scrollbar-track {*/
/*  background: #181818;*/
/*}*/

/*.articleList::-webkit-scrollbar-thumb {*/
/*  background-color: #888;*/
/*  border-radius: 4px;*/
/*}*/

/*.articleList::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #555;*/
/*}*/


.articleItem {
    display: flex;
    flex-direction: row;
    background-color: #333333;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-right: 20px;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    transition: transform 0.3s, box-shadow 0.3s;
}

.articleItem:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.articleImage {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin-right: 10px;
    object-fit: cover;
    cursor: pointer;
}

.articleDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.propertyNameLink, .propertyImageLink {
    text-decoration: none;
    color: inherit;
}

.articleName {
    font-size: 19px;
    font-weight: bold;
    color: #90caf9;
}

.articleDescription {
    font-size: 12px;
    margin: 5px 0;
    color: #ffffff;
}

.articleBookmarkText {
    font-size: 14px;
}

.propertyPrice {
    margin: 5px 0;
    color: #ffd700;
}

.propertyLocation {
    margin: 5px 0;
    color: #ffffff;
}

.bookmarkButton {
    align-self: flex-end;
    color: #f48fb1;
}

/* Responsive design */
@media (max-width: 800px) {
    .filterContainer {
        flex-direction: column;
    }

    .contentContainer {
        /*flex-direction: column;*/
    }

    .mapSection, .articleList {
        margin-right: 0;
    }

    .mapSection {
        height: 200px;
    }

    /*.articleList {*/
    /*    margin-top: 20px;*/
    /*    !*overflow-y: auto; !* 确保在移动设备上启用垂直滚动条 *!*!*/
    /*    !*height: 250px; !* Adjusted height for mobile view *!*!*/
    /*    !*height: calc(250px - 20px); !* 250 - (10px top padding + 10px bottom padding) *!*!*/
    /*    min-height: 250px; !* 好像有bug，如果不设置min-height 和max-height ，滚动条无法显示 *!*/
    /*    !*max-height: 250px;*!*/
    /*}*/
    .articleList {
        flex: 0 1 calc(50% - 10px);
        max-width: calc(50% - 10px);
        box-sizing: border-box;
    }

    .articleItem {
        flex-direction: column; /* 将 articleItem 的方向改为列布局 */
        align-items: center; /* 居中对齐内容 */
    }


    .articleDetails {
        align-items: center; /* 在移动端，内容居中对齐 */
        text-align: left; /* 使文本在居中 */
    }

    .articleName {
        font-size: 13px;
    }

    .articleDescription {
        font-size: 10px;
    }

    .articleBookmarkText {
        font-size: 11px;
    }

    .articleImage {
        margin-right: 0; /* 移除右侧间距 */
        margin-bottom: 10px; /* 增加底部间距 */
        /*width: 80px; !* 移动端缩小图片宽度 *!*/
        width: 100%; /* 移动端缩小图片宽度 */
        height: 80px; /* 相应调整高度 */
    }

    .featuredFrequentUseArticleContainer {
        font-size: 12px;
    }

    .allArticleContainer {
        font-size: 12px;
    }
}

.propertyNameLink, .propertyImageLink {
    text-decoration: none;
    color: inherit;
}
