.topBar {
    background-color: #2C3A47;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 90px;
    box-sizing: border-box;
    border-bottom: 3px solid rgba(0, 0, 0, 0.05);
}

.information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.time {
    position: absolute;
    bottom: 10px; /* 将时间移到底部 */
    text-align: center;
    width: 100%; /* 使时间居中 */
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.profileName {
    margin-top: 5px;
    color: white;
    text-align: center;
}
