.basicInfoContainer {
    background-color: #1f1f1f;
    border-radius: 18px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    color: #c9c9c9;
    /*font-family: 'Arial', sans-serif;*/
}

.sectionTitle {
    color: #1e88e5;
    margin-bottom: 14px;
    font-size: 22px;
    border-bottom: 2px solid #1e88e5;
    padding-bottom: 6px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.infoItem {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    border-bottom: 1px solid #333;
}

.infoLabel {
    font-weight: bold;
    color: #f0f0f0;
    flex: 1;
    font-size: 16px;
}

.infoValue {
    flex: 2;
    text-align: right;
    color: #f0f0f0;
    font-size: 14px;
}

.infoValue a {
    color: #1e88e5;
    text-decoration: none;
}

.infoValue a:hover {
    text-decoration: underline;
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
    .basicInfoContainer {
        padding: 14px;
    }

    .sectionTitle {
        font-size: 16px;
    }

    .infoItem {
        flex-direction: column;
        align-items: flex-start;
    }

    .infoLabel, .infoValue {
        text-align: left;
        padding: 2px 0;
    }

    .infoLabel{
        font-size: 12px;
    }

    .infoValue {
        font-size: 10px;
    }
}
