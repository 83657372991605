#categories {
    /* background-color: #e0f7fa; */
}

.section {
    padding: 80px 0;
}

.categoryTitle {
    margin-bottom: 24px;
}

.categories {
    position: relative;
    min-height: 120px; /* Adjust this value according to your needs */
    overflow: hidden;
}

.slideEnterLeft {
    opacity: 0;
    transform: translateX(-100%);
}

.slideEnterActiveLeft {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
}

.slideExitLeft {
    opacity: 1;
    transform: translateX(0);
}

.slideExitActiveLeft {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms, transform 500ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.slideEnterRight {
    opacity: 0;
    transform: translateX(100%);
}

.slideEnterActiveRight {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
}

.slideExitRight {
    opacity: 1;
    transform: translateX(0);
}

.slideExitActiveRight {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 500ms, transform 500ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.categoryItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    /* background: #fff; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
}

.categoryItem:hover {
    transform: scale(1.05);
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); */
    cursor: pointer;
}

.categoryName {
    text-align: center;
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    height: 40px;
}

.pageNavigation.hidden {
    visibility: hidden;
    margin-top: 0px;
    height: 0px;
}

.navigationButton {
    border: none;
    background: none;
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s;
}

.navigationButton.disabled {
    color: #999 !important;
    cursor: not-allowed;
}

.navigationButton:not(.disabled):hover {
    color: #007bff;
}

.pageNumber {
    margin: 0 10px;
    font-size: 1.2rem;
    font-weight: bold;
}

.circleSvg {
    /*width: 60px;*/
    /*height: 60px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*margin-bottom: 10px;*/
    /*position: relative;*/
    /*overflow: hidden;*/

    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.circleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.circleSvg .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.circleSvg .progressBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid #cecece;
    border-radius: 50%;
    box-sizing: border-box;
}

.circleSvg .progress .progressCircle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 50%;
    clip: rect(0, 60px, 60px, 30px);
    transition: transform 0.5s linear;
}

.circleSvg .progress .progressCircle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    transition: border-color 0s linear 0.5s;
}

.circleSvg .progress .progressCircleLeft {
    transform: rotate(0deg);
    z-index: 1;
}

.circleSvg .progress .progressCircleRight {
    transform: rotate(0deg);
}

.categoryItem:hover .circleSvg .progress .progressCircle::before {
    border-color: #007bff;
    transition: border-color 0s linear;
}

.categoryItem:hover .circleSvg .progress .progressCircleLeft {
    transform: rotate(180deg);
}

.categoryItem:hover .circleSvg .progress .progressCircleRight {
    transform: rotate(0deg);
    transition-delay: 0.5s;
}

.icon {
    width: 30px; /* 调整图标的宽度 */
    height: 30px; /* 调整图标的高度 */
    font-size: 30px; /* 调整图标的字体大小 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.radioLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px 5px 5px;
    border: 2px solid #ccc;
    border-radius: 30px;
    margin: 5px;
    transition: border-color 0.3s;
    background-color: #0054b0;
    color: #ecf0f1;
}

.radioLabel .MuiTypography-root {
    color: #ecf0f1;
}

.radioLabel .MuiRadio-root {
    display: none;
}

.radioLabel.Mui-checked {
    border-color: #007bff;
}

.highlightCircle {
    border: 2px solid #4590e5;
    box-shadow: 0 0 10px 5px rgba(61, 122, 255, 0.7);
    border-radius: 50%;
}

@media (max-width: 600px) {
    .categoryItem {
        padding: 5px;
    }

    .icon {
        width: 20px; /* 设置图标的宽度 */
        height: 20px; /* 设置图标的高度 */
    }

    .categoryName {
        font-size: 0.7rem;
    }

    .circleSvg {
        width: 60px;
        height: 60px;
    }

    .circleSvg .progress .progressCircle {
        clip: rect(0, 40px, 40px, 20px);
    }

    .radioLabel {
        padding: 1px 10px 1px 1px;
        border: 2px solid #ccc;
        border-radius: 30px;
        margin: 2px;
        transition: border-color 0.3s;
        background-color: #0054b0;
        color: #ecf0f1;
    }
}