/*全局文字颜色*/
* {
  color: #AAB9CF;
  font-family: 'Open Sans', sans-serif;
  /*font-family: 'Montserrat', Ariel, sans-serif;*/
}
body, h1, p, a {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*font-family: 'Open Sans', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
}
/*这些特殊地方使用通配符* 也无法设置，需要单独设置*/
textarea {
  color: #AAB9CF;
  font-family: 'Open Sans', sans-serif;
}
textarea::placeholder {
  color: #AAB9CF;
  font-family: 'Open Sans', sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}